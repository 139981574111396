import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import bannerIllus from "./banner.webp"
import Title from "../common/title"
import { useIntl } from "gatsby-plugin-react-intl"

const BannerBlog = (props) => {
  const intl = useIntl()

  return (
    <section className="mainBanner mainBannerBlog">
      <div
        style={{
          backgroundImage:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 75%), url(" +
            bannerIllus +
            ")",
        }}
      >
        <Container>
          <Row className="justify-content-center alignCenter">
            <Col md={10} xs={12}>
              <span className="caption">
                {props.caption}
              </span>
              <Title
                heading={1}
                size={2}
                extraClass="mainTitle"
                text={props.title}
              />
            </Col>
          </Row>
        </Container>
        <span className="scrollElem"></span>
      </div>
    </section>
  )
}

export default BannerBlog
