import React from "react"
import BlogSearch from "./blogSearch"
import SidebarBlogCategories from "./sidebarBlogCategories"
import SidebarRecentArticles from "./sidebarRecentArticles"
import { Container, Row, Col } from "react-bootstrap"
import "../../templates/blog/blog.scss"
import StyledButton from "../common/styledButton"
import SidebarArchives from "./sidebarArchives"
import SpecialOffers from "./vr-special-offers-banner.jpg"
import HotelAndFlight from "./hotel-and-flight.jpg"
import OffresSpeciales from "./vr-offres-speciales-banner.jpg"
import HotelEtVol from "./hotel-et-vol.jpg"

const LayoutBlog = ({ children, language, templateType }) => {
  return (
    <>
      <script async defer data-pin-hover="true" src="//assets.pinterest.com/js/pinit.js"></script>
      <section className="blogLayout">
        <Container id="fixed-elements">
          <Row>
            <Col md={9}>{children}</Col>
            <Col md={3}>
              <div
                className="sidebar"
              >
                <BlogSearch language={language} />
                {templateType === "innerBlog" && (
                  <StyledButton
                    title={language === "EN" ? "Blog Home" : "Accueil Blog"}
                    path={language === "EN" ? "/blog" : "/blog/fr"}
                    type="transparent"
                  />
                )}
                {/* <SidebarArchives language={language} /> */}
                <SidebarRecentArticles language={language} />
                <SidebarBlogCategories language={language} />
              </div>
              {/*{language == "FR" ?*/}
              {/*    <div className="commercial">*/}
              {/*      <a href="https://www.veranda-resorts.com/hotel-ile-maurice/offres-speciales?utm_source=blog_vr_banner" target="_blank">*/}
              {/*        <img src={OffresSpeciales} width={340} height={190} alt={SpecialOffers}/>*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*    :*/}
              {/*    <div className="commercial">*/}
              {/*      <a href="https://www.veranda-resorts.com/en/mauritius-hotels/special-offers?utm_source=blog_vr_banner_en" target="_blank">*/}
              {/*        <img src={SpecialOffers} width={340} height={190} alt={OffresSpeciales}/>*/}
              {/*    </a>*/}
              {/*    </div>*/}
              {/*}*/}
              {/*{language == "FR" ?*/}
              {/*    <div className="commercial">*/}
              {/*      <a href="http://www.verandaresortsbookings.com/fr/?utm_source=vrblog-fr&utm_medium=banner&utm_campaign=VR-Travel" target="_blank">*/}
              {/*        <img src={HotelEtVol} width={340} height={190} alt={HotelEtVol}/>*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*    :*/}
              {/*    <div className="commercial">*/}
              {/*      <a href="http://www.verandaresortsbookings.com/en/?utm_source=vrblog-en&utm_medium=banner&utm_campaign=VR-Travel" target="_blank">*/}
              {/*        <img src={HotelAndFlight} width={340} height={130} alt={HotelAndFlight}/>*/}
              {/*        {}*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*}*/}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default LayoutBlog
