import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import HTMLReactParser from "html-react-parser"
import { formatPostDate } from "../../services/post.service"
import { Link } from "gatsby"
import {useIntl} from "gatsby-plugin-react-intl"

const MainListArticles = ({ articles, results }) => {

  const intl = useIntl()

  return (
    <div
      className="blk-list-articles articlesList-"
      data-total={results.length > 0 ? results.length > 0 : articles.length}
    >
      {results.length > 0
        ? results.map(article => (
            <div key={article.id}>
              <div>
                <Link to={"/blog" + article.path}>
                  <div className="article-image">
                    <GatsbyImage image={getImage(article.image)} />
                  </div>
                </Link>
                <div className="articleListing">
                  {article.categories.nodes.length > 0 &&
                      article.categories.nodes.map(
                          (category, index) => {
                            if (
                                index !== article.categories.nodes.length - 1
                            ){
                              return <Link to={"/blog" + category.uri} key={index}>{category.name} / </Link>
                            } else {
                              return <Link to={"/blog" + category.uri} key={index}>{category.name}</Link>
                            }
                          }
                      )
                  }
                </div>
                <Link  to={"/blog" + article.path}>
                  <h2>{article.title}</h2>
                  <span className="dateInfo">{formatPostDate(article.date, intl.locale)}</span>
                  <div className="article-excerpt">
                    {HTMLReactParser(article.excerpt)}
                  </div>
                </Link>
              </div>
              <div className="article-read-more">
                <Link className="simpleLink" to={"/blog" + article.path}>
                  Continue
                </Link>
              </div>
            </div>
          ))
        : articles.map(article => (
            <div key={article.id}>
              <div>
                <Link to={"/blog" + article.uri}>
                  <div className="article-image">
                    <GatsbyImage
                        image={getImage(article.featuredImage?.node?.gatsbyImage)}
                    />
                  </div>
                </Link>
                <div className="articleListing">
                  {article.categories.nodes.length > 0 &&
                      article.categories.nodes.map(
                          (category, index) => {
                            if (
                                index !== article.categories.nodes.length - 1
                            ){
                              return <Link to={"/blog" + category.uri} key={index}>{category.name} / </Link>
                            } else {
                              return <Link to={"/blog" + category.uri} key={index}>{category.name}</Link>
                            }
                          }
                      )
                  }
                </div>
                <Link to={"/blog" + article.uri}>
                  <h2>{article.title}</h2>
                  <span className="dateInfo">{formatPostDate(article.date, intl.locale)}</span>
                  <div className="article-excerpt">
                    {HTMLReactParser(article.excerpt)}
                  </div>
                </Link>
              </div>
              <div className="article-read-more">
                <Link className="simpleLink" to={"/blog" + article.uri}>
                  {article.language.slug === "fr" ? "Continuer" : "Continue"}
                </Link>
              </div>
            </div>
          ))}
    </div>
  )
}

export default MainListArticles
