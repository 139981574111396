import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const SidebarBlogCategories = ({ language }) => {
  const data = useStaticQuery(graphql`
    query WPCategoriesQuery {
      WPCategoriesEN: allWpCategory(
        filter: { language: { code: { eq: EN } } }
      ) {
        nodes {
          count
          name
          uri
          id
          language {
            code
          }
        }
      }
      WPCategoriesFR: allWpCategory(
        filter: { language: { code: { eq: FR } } }
      ) {
        nodes {
          count
          name
          uri
          id
          language {
            code
          }
        }
      }
    }
  `)

  let categories
  switch (language) {
    case "FR":
      categories = data.WPCategoriesFR.nodes
      break
    default:
      categories = data.WPCategoriesEN.nodes
  }

  return (
    <div className="blkFiltre">
      <h3>{language == "FR" ? "Toutes les catégories" : "All Categories"}</h3>
      <ul>
        {categories.map(category => (
          <>
            {category.count != null && (
              <li key={category.id}>
                <Link to={"/blog" + category.uri}>{category.name}</Link>
              </li>
            )}
          </>
        ))}
      </ul>
    </div>
  )
}

export default SidebarBlogCategories
