import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const SidebarRecentArticles = ({ language }) => {
  const data = useStaticQuery(graphql`
    query RecentArticlesQuery {
      WPPostsEN: allWpPost(
        limit: 3
        sort: { fields: date, order: DESC }
        filter: { language: { code: { eq: EN } } }
      ) {
        nodes {
          language {
            code
          }
          title
          uri
        }
      }
      WPPostsFR: allWpPost(
        limit: 3
        sort: { fields: date, order: DESC }
        filter: { language: { code: { eq: FR } } }
      ) {
        nodes {
          language {
            code
          }
          title
          uri
        }
      }
    }
  `)

  let recentPosts
  switch (language) {
    case "FR":
      recentPosts = data.WPPostsFR.nodes
      break
    default:
      recentPosts = data.WPPostsEN.nodes
  }

  return (
    <div className="blkFiltre">
      <h3>{language == "FR" ? "Articles récents" : "Recent articles"}</h3>
      <ul>
        {recentPosts.map(post => (
          <li key={post.uri}>
            <Link to={"/blog" + post.uri}>{post.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SidebarRecentArticles
