import { navigate } from "gatsby-plugin-react-intl"
import React, { useRef, useState } from "react"

const BlogSearch = ({ initialQuery = "", language }) => {
  const [query, setQuery] = useState(initialQuery)

  // We need to get reference to the search input element
  const inputEl = useRef(null)

  // On input change use the current value of the input field (e.target.value)
  // to update the state's query value
  const handleChange = e => {
    setQuery(e.target.value)
  }

  // When the form is submitted navigate to /search
  // with a query q paramenter equal to the value within the input search
  const handleSubmit = e => {
    e.preventDefault()
    // `inputEl.current` points to the mounted search input element
    const q = inputEl.current.value

    if (q.length === 0) {
      return false
    }

    switch (language.toLowerCase()) {
      case "fr":
        navigate(`/blog/fr/?s=${q}`)
        break
      default:
        navigate(`/blog/?s=${q}`)
    }
  }

  return (
    <div className="search-blog">
      <form role="search" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder={language == "FR" ? "Rechercher" : "Search our blog"}
          ref={inputEl}
          value={query}
          onChange={handleChange}
        />
        <button className="btnSubmitSearch" type="submit">
          <i className="icon-search"></i>
        </button>
      </form>
    </div>
  )
}

export default BlogSearch
