import React, { lazy, Suspense } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { graphql } from "gatsby"
import { navigate, useIntl } from "gatsby-plugin-react-intl"
import MainListArticles from "../../components/blog/mainListArticles"
import LayoutBlog from "../../components/blog/layoutBlog"
import BannerBlog from "../../components/blog/bannerBlog"
import Breadcrumbs from "../../components/common/breadcrumbs/breadcrumbs"
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"
import { setSeoData } from "../../services/seo.service"

export default function BlogListing({
  data: { allWpPost, wpCategory },
  pageContext,
}) {
  const isSSR = typeof window === "undefined"

  const intl = useIntl()
  const { name: title, seo } = wpCategory

  const seoData = setSeoData({
    lang: pageContext.localeBlog.toLowerCase(),
    title: title,
    field_seo: {
      description: seo.metaDesc,
      title: seo.title,
      og_description: seo.opengraphDescription,
      og_image: seo.opengraphImage
        ? process.env.GATSBY_URL + seo.opengraphImage.publicUrl
        : "",
      og_locale: pageContext.localeBlog.toLowerCase(),
      og_title: seo.opengraphTitle,
      og_type: seo.opengraphType,
    },
    image: seo.opengraphImage
      ? process.env.GATSBY_URL + seo.opengraphImage.publicUrl
      : "",
  })

  const blogTranslatedUrl = JSON.parse(pageContext.translatedUrls)
  const blogUrlByLocale =
    pageContext.localeBlog === "FR"
      ? blogTranslatedUrl["fr"]
      : blogTranslatedUrl["en"]
  const [page, setPage] = React.useState(1)

  const handleChange = (event, value) => {
    setPage(value)
    if (value === 1) {
      navigate(blogUrlByLocale)
    } else {
      navigate(blogUrlByLocale + "/page/" + value)
    }
  }

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="pageBlog">
      <Seo data={seoData} />
      <BannerBlog title="" caption="" />
      <Breadcrumbs pageTitle={title} localeBlog={pageContext.localeBlog} />
      <LayoutBlog language={pageContext.localeBlog}>
        <MainListArticles articles={allWpPost.nodes} results={[]}/>
        <Stack spacing={2}>
          <Pagination
            count={pageContext.numPages}
            page={pageContext.currentPage}
            onChange={handleChange}
          />
        </Stack>
      </LayoutBlog>
    </Layout>
  )
}

export const query = graphql`
  query (
    $skip: Int!
    $limit: Int!
    $localeBlog: WpLanguageCodeEnum!
    $categoryId: String!
  ) {
    allWpPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } }
        language: { code: { eq: $localeBlog } }
      }
      
    ) {
      nodes {
        title
        uri
        id
        date
        excerpt
        language {
          slug
        }
        translations {
          language {
            slug
          }
          uri
        }
        featuredImage {
          node {
            gatsbyImage(width: 1920, formats: WEBP)
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
    wpCategory(id: { eq: $categoryId }) {
      name
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphImage {
          publicUrl
        }
      }
    }
  }
`
