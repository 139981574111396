const getBlogLink = locale => {
  let link = "/blog"

  if (locale !== "en") {
    link += "/" + locale
  }

  return link
}

const getPostLink = postLink => {
  return "/blog" + postLink
}

const formatPostDate = (postDate, locale) => {
  const fullDate = new Date(postDate)

  const date = fullDate.getDate()
  const month = fullDate.toLocaleString(locale, { month: "long" })
  const year = fullDate.getFullYear()

  return date + " " + month + " " + year
}

export { getBlogLink, getPostLink, formatPostDate }
